<template>
  <div class="panel">
        <div class="left">
            <div class="counter-container">
                <div class="caption">
                    <h4>Tijd voor Grondwetloop:</h4>
                    <h5>Exclusief 5 minuten voor het eindspel</h5>
                </div>
                <div class="counter-content">
                    <button class="counter-button counter-subtract centered" @click="changeTime(-1)">
                        <p class="counter-index">-</p>
                    </button> 

                    <div class="counter-display centered">
                        <div class="counter-time">{{ selectedTime }}</div> 
                        minuten
                    </div> 
                    <button class="counter-button counter-add centered" @click="changeTime(1)">
                        <p class="counter-index">+</p>
                    </button>
                </div>
            </div>
        </div>
        <div class="right">
            <app-button ref="submit" color="purple" bevel="true" :caption="$t('button-next')"  @click="handleSubmit" />
            <app-button  v-if='!this.$store.getters["user/isInVWNW"]' class="prevbutton" color="purple" bevel="false" :caption="$t('button-prev')"  @click="handlePrev" />
        </div>
  </div>
</template>

<script>

import AppButton from  "@/components/ui/AppButton.vue";

export default {
    name: "SetDurationScreen",
    components: {
      AppButton,
    },    
    props: {
    },
    data() {
        return {
            selectedTime : this.$store.getters['gamesettings/duration'],
            minTime: 15,
            maxTime: 35
        };
    },
    methods: {
        changeTime(amount) {
            let newTime = this.selectedTime + amount;
            if (newTime >= this.minTime && newTime <= this.maxTime) {
                this.selectedTime = newTime;
                 
            }
        },
        handleSubmit() {

            this.$store.dispatch("gamesettings/setGameDuration",this.selectedTime);            

            if (process.env.NODE_ENV === "development") {
               // this.$store.dispatch("gamesettings/setGameDuration",1);
            }

            this.$router.push('level');
        },
        handlePrev() {
            this.$router.push('room');
        }        
    },
    mounted() {
        // if (process.env.NODE_ENV === "development") {
        //     this.timeslots.unshift(1);
        // }
        console.log( this.$store.getters['gamesettings/duration'] )
        this.selectedTime = this.$store.getters['gamesettings/duration'];
        this.$store.dispatch("setHeaderContent", {caption: "Instellen"} );
    },
    computed: {
    },
};
</script>


<style scoped>

.panel {
    position: absolute;
    background-color: var(--green-middle-color);
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
}

.left {
    width:75%;
    padding-left:8%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.counter-content {
    margin-top: calc(100px / var(--scale) ); 
    display: flex;
    align-items: center;
}

.counter-button {
    border: none;
    outline: none;
    position: relative;
    border-radius: 50%;
    width:  calc(100px / var(--scale) ); 
    height:  calc(100px / var(--scale) ); 
    background: var(--purple-dark-color);
    font: calc(150px / var(--scale) )/ calc(120px / var(--scale) ) Fira Sans Bold;

}

.counter-time {
    font: calc(70px / var(--scale) )/ calc(80px / var(--scale) ) Fira Sans Bold;
}

.counter-display {
    width: 55px;
    height: 55px;
    margin: 0 -25px;

    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font: calc(35px / var(--scale) )/ calc(43px / var(--scale) ) Fira Sans Bold;
    color: var(--purple-dark-color);
    background-color:#bcdaad ;
    width: calc(268px / var(--scale) ); 
    height: calc(268px / var(--scale) ); 
    border-radius: 50%;

}

.counter-amount {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    display: block;
    font-size: 30px;
    font-weight: 500;
    background: none;
    text-align: center;
    font: calc(40px / var(--scale) )/ calc(49px / var(--scale) ) Fira Sans Bold;

}

.counter-index {
    font-size: calc(70px / var(--scale) );
    line-height: 35px;
    color: #fff;
}

/* ul {
    list-style: none;
    margin:0;
    padding:0;
} */

.caption {
    color: var(--purple-dark-color);
    font: calc(37px / var(--scale) )/ calc(43px / var(--scale) ) Fira Sans Bold;
    /* background-color: red; */
}

.caption h5 {
    font: calc(25px / var(--scale) )/ calc(43px / var(--scale) ) Fira Sans Regular;
}

/* .item {
    width: calc(395px / var(--scale) ); 
    height: calc(67px / var(--scale) ); 
    padding:9px 0 12px 20px;
    margin-bottom:17px;
    background-color: white;
    color:black;
    font: normal normal bold  calc(35px / var(--scale) )/ calc(43px / var(--scale) ) Fira Sans;   
    border:2px solid white;
}

.selected {
    border:2px solid var(--purple-dark-color);
    color: var(--purple-dark-color);
} */


.right {
    width:15%;
    display: flex;
    flex-direction: column;
    align-items:flex-end;
    padding-top: calc(360px / var(--scale) ); 
    /* border:1px solid red; */
    /* justify-content: center; */
}

.prevbutton {
    margin-top:40px;
    margin-right:-5px;
}

</style>