<template>
  <div class="panel">
        <div class="left">
            <div class="caption">Niveau</div>
            <ul>
                <li 
                 v-for="(level,index) in this.$store.getters['gamesettings/levels']"  
                :key="index" 
                :class="['item',selectedLevel === level.id ? 'selected':'']"
                @click="handleSelect(level.id)">
                    {{level.caption}}       
                </li>
            </ul>
        </div>
        <div class="center">

        </div>
        <div class="right">
            <app-button ref="submit" color="purple" bevel="true" :caption="$t('button-next')"  @click="handleSubmit" />
            <app-button class="prevbutton" color="purple" bevel="false" :caption="$t('button-prev')"  @click="handlePrev" />
        </div>
  </div>
</template>

<script>

import AppButton from  "@/components/ui/AppButton.vue";

export default {
    name: "SetupLevelScreen",
    components: {
      AppButton,
    },    
    props: {
    },
    data() {
        return {
        };
    },
    methods: {
        handleSelect(level) {
            //console.log("level: " + level)
            this.$store.dispatch("gamesettings/setGameLevel",level);
            this.$refs.submit.enabled(true);
        },
        handleSubmit() {
            this.$router.push('rounds');

        },
        handlePrev() {
            this.$router.push('time');
        }        
    },
    mounted() {
        this.$store.dispatch("setHeaderContent", {caption: "Instellen"} );
        this.$refs.submit.enabled(this.selectedLevel);

        //console.log( this.$store.getters['gamesettings/levels'] )
    },
    computed: {
       selectedLevel() {
            return this.$store.getters["gamesettings/level"];
        },
    },
};
</script>


<style scoped>

.panel {
    position: absolute;
    background-color: var(--green-middle-color);
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
}

.left {
    width:38%;
    padding-left:6%;
    /* display: flex;
    flex-direction: column; */
    /* align-items: center; */
}

ul {
    list-style: none;
    margin:0;
    padding:0;
}

.caption {
    margin-top: calc(200px / var(--scale) ); 
    margin-bottom: 20px;
    color: var(--purple-dark-color);
    font: calc(35px / var(--scale) )/ calc(43px / var(--scale) ) Fira Sans Bold;
}

.item {
    width: calc(395px / var(--scale) ); 
    height: calc(67px / var(--scale) ); 
    padding:9px 0 12px 20px;
    margin-bottom:17px;
    background-color: white;
    color:black;
    font: calc(35px / var(--scale) )/ calc(43px / var(--scale) ) Fira Sans Bold;   
    border:2px solid white;

}

.selected {
    border:2px solid var(--purple-dark-color);
    color: var(--purple-dark-color);
}

.center {
    width:40%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.right {
    width:15%;
    display: flex;
    flex-direction: column;
    align-items:flex-end;
    padding-top: calc(240px / var(--scale) ); 
    /* border:1px solid red; */
    /* justify-content: center; */
}

.prevbutton {
    margin-top:40px;
    margin-right:-5px;
}

</style>